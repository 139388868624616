import React from 'react';
import SettingPage from "../components/setting";

const Setting = () => {
    return (
        <>
            <SettingPage />
        </>
    );
};

export default Setting;