import React from 'react';
import UserListDetailPage from "../components/userList/userListDetail";

const UserListDetail = () => {
    return (
        <>
            <UserListDetailPage />
        </>
    );
};

export default UserListDetail;