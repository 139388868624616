import React from 'react';
import AddQuestionnairePage from '../components/questionnaire/AddQuestionnairePage';


const AddQuestionnaire = () => {
    return (
        <>
            <AddQuestionnairePage />
        </>
    );
};

export default AddQuestionnaire;