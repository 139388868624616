import React from 'react';
import AddDetailyTaskPage from "../components/dailyTask/AddDetailyTaskPage";

const AddDailyTask = () => {
    return (
        <>
            <AddDetailyTaskPage />
        </>
    );
};

export default AddDailyTask;