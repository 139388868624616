import React from 'react';
import RecordQuestionnairePage from "../components/questionnaire/RecordQuestionnairePage";


const Questionnaire = () => {
    return (
        <>
            <RecordQuestionnairePage />
        </>
    );
};

export default Questionnaire;