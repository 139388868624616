import React from 'react';
import DailyTaskPage from "../components/dailyTask";
import ArticlePage from "../components/article";

const Article = () => {
    return (
        <>
            <ArticlePage />
        </>
    );
};

export default Article;