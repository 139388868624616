import React from 'react';
import UserListPage from "../components/userList";

const UserList = () => {
    return (
        <>
            <UserListPage />
        </>
    );
};

export default UserList;