import React from 'react';
import DailyTaskPage from "../components/dailyTask";

const DailyTask = () => {
    return (
        <>
            <DailyTaskPage />
        </>
    );
};

export default DailyTask;