import React from 'react';
import AddDetailyTaskPage from "../components/dailyTask/AddDetailyTaskPage";
import AddArticlePage from "../components/article/AddDetailyTaskPage";

const AddArticle = () => {
    return (
        <>
            <AddArticlePage />
        </>
    );
};

export default AddArticle;